.hero {
    background: $color-space;

    .hero-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint-down(small) {
            flex-direction: column-reverse;
        }

        .hero-left {
            width: 50%;

            @include breakpoint-down(small) {
                width: 100%;
                text-align: center;
            }

            .hero-content-wrapper {
                padding: calc(127vw / var(--context)) 0;
                max-width: calc(582vw / var(--context));
                width: 100%;

                @include breakpoint-down(small) {
                    padding-bottom: calc(50vw / var(--context));
                    padding-top: 0;
                }

                h1 {
                    margin-bottom: 0;
                    color: $color-white;
                    font-weight: 600;
                    line-height: 1.37;
                }

                p {
                    color: $color-white;
                    margin-bottom: 0;
                    opacity: 0.7;
                    font-weight: 400;
                    line-height: 1.75;
                    margin-top: calc(24vw / var(--context));
                }

                .hero-buttons {
                    margin-top: calc(48vw/ var(--context));

                    .pricing-btn {
                        svg {
                            margin-left: calc(10vw / var(--context));
                        }
                    }
                }
            }

            h1 {
                font-family: $font-def;

            }
        }

        .hero-right {
            width: 50%;
            display: flex;
            justify-content: flex-end;

            @include breakpoint-down(small) {
                width: 100%;
                padding: calc(50vw / var(--context)) 0;
            }

            img {
                width: calc(638vw / var(--context));
            }
        }
    }
}

// How We Work Section Start Here

.how-we-work {
    padding: calc(128vw / var(--context)) 0;
    background-color: $color-grey;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;

    }

    .how-we-work-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        .how-we-work-left {
            max-width: calc(700vw / var(--context));
            width: 26%;

            .circle-animate {
                position: relative;
                margin-top: calc(50vw / var(--context));
                @include breakpoint-down(small) {
                    display: none;
                }

                .first-circle-animation {
                    height: calc(250vw / var(--context));
                    width: calc(250vw / var(--context));
                    background-color: $color-jasmine;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    mix-blend-mode: multiply;
                    filter: blur(calc(50vw / var(--context)));
                    animation: yellow-circle-animation 5s infinite;
                }

                .second-circle-animation {
                    height: calc(250vw / var(--context));
                    width: calc(250vw / var(--context));
                    background-color: $color-blue;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    mix-blend-mode: multiply;
                    filter: blur(calc(50vw / var(--context)));
                    animation: blue-circle-animation 5s linear infinite;
                }

                @keyframes yellow-circle-animation {
                    0% {
                        transform: translate(0px, 0px) scale(1);
                    }
                    33%{
                        transform: translate(30px, -50px) scale(1.1);
                    }

                    66%{
                        transform: translate(-20px, 20px)scale(0.9);
                    }

                    100%{
                        transform: translate(0px, 0px) scale(1);
                    }
                }

                @keyframes blue-circle-animation {

                    0% {
                        transform: translate(0px, 0px) scale(1);
                    }
                    33%{
                        transform: translate(30px, -50px) scale(1.1);
                    }

                    66%{
                        transform: translate(-20px, 20px)scale(0.9);
                    }

                    100%{
                        transform: translate(0px, 0px) scale(1);
                    }
                }
            }

            @include breakpoint-down(small) {
                width: 100%;
            }

            h2 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.33;
                margin-bottom: 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            p {
                color: $color-peacoat;
                font-size: calc(16vw / var(--context));
                font-weight: 400;
                margin-bottom: 0;
                padding: calc(16vw / var(--context)) 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            a {
                color: $color-blue;
                font-size: calc(18vw /var(--context));
                font-weight: 500;
                line-height: 1.77;
                display: inline-flex;
                align-items: center;

                @include breakpoint-down(small) {
                    display: block;
                    text-align: center;
                }

                svg {
                    margin-left: calc(10vw / var(--context));
                }
            }
        }

        .how-we-work-right {
            width: 74%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            .how-we-work-icon-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                align-items: flex-start;
                gap: calc(50vw / var(--context));

                @include breakpoint-down(small) {
                    gap: unset;
                    justify-content: space-evenly;
                    align-items: unset;
                    padding-top: calc(50vw / var(--context));
                }


                .how-we-work-icon-left {
                    width: 36%;

                    @include breakpoint-down(small) {
                        width: 50%;
                    }

                    .card {
                        img {
                            width: calc(50vw / var(--context));

                            @include breakpoint-down(small) {
                                display: block;
                                margin: 0 auto;
                            }
                        }

                        h4 {
                            padding-top: calc(16vw / var(--context));
                            padding-bottom: calc(8vw / var(--context));
                            color: $color-peacoat;
                            font-weight: 500;
                            line-height: 1.5;
                            margin-bottom: 0;

                            @include breakpoint-down(small) {
                                text-align: center;
                            }
                        }

                        p {
                            color: $color-peacoat;
                            font-size: calc(16vw / var(--context));
                            font-weight: 400;
                            line-height: 1.75;

                            @include breakpoint-down(small) {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

// How We Work Section Ends Here

// Our Project Section Start Here

.our-projects {
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    .out-project-title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: calc(64vw / var(--context));


        h2 {
            color: $color-peacoat;
            font-weight: 600;
            line-height: 1.33;
            margin-bottom: 0;
        }

        p {
            color: $color-peacoat;
            font-size: calc(16vw / var(--context));
            font-weight: 500;
            line-height: 1.75;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            svg {
                margin-left: calc(10vw / var(--context));
            }
        }
    }

    .our-project-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include breakpoint-down(small) {
            flex-direction: column;
            gap: calc(20vw / var(--context));
        }

        .our-project-left {
            width: calc(70% - 32vw / var(--context));

            @include breakpoint-down(small) {
                width: 100%;
            }

            .card {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 47%;
                    height: 100%;
                    background: linear-gradient(306deg, #1C1E53 0%, rgba(28, 30, 83, 0.42) 100%);
                    z-index: 1;

                    @include breakpoint-down(small) {
                        width: 100%;
                    }
                }

                img {
                    position: relative;
                    max-width: calc(843vw / var(--context));
                    width: 100%;
                }

                .project-content {
                    width: calc(313vw / var(--context));
                    position: absolute;
                    bottom: 11%;
                    left: 6%;
                    z-index: 3;

                    h5 {
                        color: $color-white;
                        font-weight: 600;
                        line-height: 1.5;
                        margin-bottom: 0;
                    }

                    p {
                        padding-top: calc(16vw / var(--context));
                        padding-bottom: calc(54vw / var(--context));
                        color: $color-white;
                        font-size: calc(16vw / var(--context));
                        font-weight: 500;
                        line-height: 1.75;
                        opacity: .8;
                        margin-bottom: 0;

                    }

                    a {
                        color: $color-jasmine;
                        font-size: calc(16vw / var(--context));
                        line-height: 1.75;
                        font-weight: 500;
                        display: inline-flex;
                        align-items: center;

                        svg {
                            margin-left: calc(10vw/ var(--context));
                        }
                    }
                }
            }
        }

        .our-project-right {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include breakpoint-down(small) {
                flex-direction: column;
                width: 100%;

                &:nth-child(2) {
                    img {
                        @include breakpoint-down(small) {
                            margin-top: calc(20vw / var(--context));
                        }
                    }
                }
            }

            .card {
                position: relative;

                &:first-child:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(306deg, #1C1E53 0%, rgba(28, 30, 83, 0.42) 100%);
                    z-index: 1;

                }

                img {
                    max-width: calc(405vw / var(--context));
                    width: 100%;
                    min-height: calc(284vw/ var(--context));

                    @include breakpoint-down(small) {
                        max-width: 100%;
                        width: 100% !important;
                    }
                }

                .project-content {
                    position: absolute;
                    bottom: 11%;
                    left: 6%;
                    z-index: 3;

                    h5 {
                        color: $color-white;
                        font-weight: 600;
                        line-height: 1.5;
                        margin-bottom: 0;
                    }

                    a {
                        color: $color-jasmine;
                        font-size: calc(16vw / var(--context));
                        line-height: 1.75;
                        font-weight: 500;
                        display: inline-flex;
                        align-items: center;

                        svg {
                            margin-left: calc(10vw/ var(--context));
                        }
                    }
                }
            }
        }
    }
}

// Our Project Section Ends Here

// features section Start here

.features {
    padding: calc(128vw / var(--context)) 0;
    background: $color-grey;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    .features-title {
        max-width: calc(630vw / var(--context));
        width: 100%;
        margin: 0 auto;

        span {
            display: block;
            text-align: center;
            color: $color-peacoat;
            font-weight: 500;
            font-size: calc(16vw / var(--context));
            line-height: 1.75;
        }

        h2 {
            text-align: center;
            font-weight: 600;
            line-height: 1.33;
            color: $color-peacoat;
            margin-bottom: 0;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin-right: calc((-16vw / var(--context)));
        margin-left: calc((-16vw / var(--context)));
        margin-top: calc(31vw / var(--context));

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        .col {
            width: 33.33%;
            padding: calc(16vw / var(--context));

            @include breakpoint-down(small) {
                width: 100%;
            }
        }

        .card {
            background-color: $color-white;
            padding: calc(58vw / var(--context)) calc(48vw / var(--context)) calc(48vw / var(--context)) calc(32vw / var(--context));
            @include breakpoint-down(small){
                padding: calc(32vw / var(--context));

            }

            img {
                width: calc(32vw / var(--context));
                height: calc(27vw / var(--context));

                @include breakpoint-down(small) {
                    display: block;
                    margin: 0 auto;
                }
            }

            h5 {
                margin-bottom: 0;
                padding: calc(22vw / var(--context)) 0 calc(12vw /var(--context)) 0;
                color: $color-peacoat;
                font-weight: 400;
                line-height: 1.5;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            p {
                font-weight: 400;
                line-height: 1.75;
                color: $color-peacoat;
                font-size: calc(16vw / var(--context));

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }
        }
    }
}

// features section Ends here

// Testimonials section start here

.testimonials {
    padding: calc(128vw / var(--context)) 0;
    background-color: $color-accent;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    .testimonial-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        .testimonial-left {
            width: 30%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            h3 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.47;
                margin-bottom: 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            p {
                color: $color-peacoat;
                font-weight: 400;
                font-size: calc(16vw / var(--context));
                line-height: 1.75;
                padding-top: calc(16vw / var(--context));

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }
        }

        .testimonial-right {
            width: 70%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            .testimonial-slider-wrapper {
                h4 {
                    color: $color-peacoat;
                    font-weight: 500;
                    line-height: 1.81;
                    @include breakpoint-down(small){
                        font-size: calc(20vw / var(--context));
                    }
                }

                .testimonial-slider-inner-wrapper {
                    .testimonial-client {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;

                        .client-img {
                            width: calc(60vw / var(--context))
                        }

                        .client-name {
                            margin-left: calc(10vw / var(--context));

                            p {
                                margin-bottom: 0;
                                color: $color-peacoat;
                                font-weight: 500;
                                line-height: 1.77;
                            }

                            span {
                                color: $color-peacoat;
                                font-weight: 500;
                                line-height: 2.33;
                                font-size: calc(12vw / var(--context));
                            }
                        }

                        img {
                            width: calc(58vw / var(--context));
                            border-radius: 50%;
                        }
                    }

                }
            }

            .swiper {
                .swiper-button-next {
                    &::after {
                        content: "";
                    }
                }

                .swiper-button-prev {
                    &::after {
                        content: "";

                    }
                }

                .swiper-wrapper {
                    .swiper-navigation {
                        position: relative;
                        width: calc(100vw / var(--context));
                        height: calc(50vw / var(--context));
                        right: 20%;
                        bottom: 10%;

                        @include breakpoint-down(small) {
                            right: 2%;
                            position: absolute;
                            bottom: 0%;
                            width: calc(100vw / var(--context));
                        }

                        .swiper-button-next {
                            svg {
                                position: absolute;
                                top: 0;
                                left: 0;
                                fill: $color-white;
                                background-color: $color-blue;
                                height: calc(40vw / var(--context));
                                width: calc(40vw / var(--context));
                                border-radius: calc(40vw / var(--context));
                                padding: calc(5vw / var(--context));
                            }
                        }

                        .swiper-button-prev {
                            svg {
                                fill: $color-peacoat;
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: calc(40vw / var(--context));
                                width: calc(40vw / var(--context));
                                border-radius: calc(40vw / var(--context));
                                padding: calc(5vw / var(--context));

                                &:hover {
                                    background-color: $color-blue;
                                    fill: $color-white;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
}

// Testimonials section ends here

// Faq section start here

.faq {
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    .faq-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        .faq-left {
            width: 25%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            h3 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.47;
                margin-bottom: 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            p {
                color: $color-blue;
                font-weight: 500;
                font-size: calc((18vw / var(--context)));
                line-height: 1.77;
                margin-bottom: 0;
                padding-top: calc(16vw / var(--context));

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }
        }

        .faq-right {
            width: 65%;

            @include breakpoint-down(small) {
                width: 100%;
                padding-top: calc(50vw / var(--context));
            }

            .faq-question-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-bottom: calc(2vw/ var(--context)) solid $color-grey;
                padding-left: calc(10vw / var(--context));
                padding-bottom: calc(48vw / var(--context));
                margin-top: calc(32vw / var(--context));

                &:first-child {
                    margin: 0;
                }

                .faq-question-number {
                    width: calc(100vw / var(--context));
                    @include breakpoint-down(small){
                        width: calc(15vw / var(--context));
                    }

                    h5 {
                        font-weight: 500;
                        line-height: 1.5;
                        color: $color-blue;
                        margin-bottom: 0;
                    }
                }

                .faq-question {
                    width: calc(100% - 150vw / var(--context));

                    h5 {
                        color: $color-peacoat;
                        font-weight: 500;
                        margin-bottom: 0;
                        line-height: 1.5;
                    }
                }

                .faq-btn {
                    width: calc(50vw / var(--context));

                    svg {
                        width: calc(16vw / var(--context));
                        opacity: .7;
                        color: $color-peacoat;
                    }
                }

                p {
                    font-weight: 500;
                    color: $color-peacoat;
                    line-height: 1.5;
                    margin-bottom: 0;
                    padding-top: calc(16vw / var(--context));
                    opacity: .8;
                }
            }
        }
    }
}

// Faq section ends here

// Enquiry Section start here

.enquiry {
    margin-bottom: calc(128vw / var(--context));

    @include breakpoint-down(small) {
        margin-bottom: calc(50vw / var(--context));
    }

    .enquiry-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        height: 100%;
        width: 100%;

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(115deg, rgba(28, 30, 83, 0.6), 100%, rgba(28, 30, 83, 0.1) 100%);
            z-index: 1;

            @include breakpoint-down(small) {
                width: 100%;
            }
        }

        .enquiry-left {
            width: 50%;
            position: relative;

            @include breakpoint-down(small) {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .enquiry-text {
                position: absolute;
                top: 0;
                left: 0;
                padding: calc(96vw / var(--context));
                z-index: 2;

                @include breakpoint-down(small) {
                    padding: calc(15vw / var(--context));
                }

                h1 {
                    color: $color-white;
                    font-weight: 600;
                    line-height: 1.37;
                    margin-bottom: 0;

                    @include breakpoint-down(small) {
                        text-align: center;
                    }
                }

                p {
                    padding-top: calc(32vw / var(--context));
                    color: $color-white;
                    font-size: calc(16vw / var(--context));
                    font-weight: 500;
                    line-height: 1.75;
                    margin-bottom: 0;

                    @include breakpoint-down(small) {
                        text-align: center;
                    }
                }
            }
        }

        .enquiry-right {
            width: 50%;
            background-color: $color-space;
            padding: calc(96vw / var(--context)) calc(96vw / var(--context)) calc(48vw / var(--context)) calc(96vw / var(--context));
            position: relative;
            z-index: 2;

            @include breakpoint-down(small) {
                width: 100%;
                padding: calc(15vw / var(--context));
            }

            h4 {
                color: $color-white;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            p {
                color: $color-grey;
                padding-top: calc(16vw / var(--context));
                font-size: calc(16vw / var(--context));
                font-weight: 500;
                line-height: 1.75;
                margin-bottom: 0;

                @include breakpoint-down(small) {
                    text-align: center;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                margin-top: calc(40vw / var(--context));

                input {
                    padding: calc(18vw / var(--context)) 0 calc(18vw / var(--context)) calc(32vw / var(--context));
                    background-color: transparent;
                    border-radius: calc(8vw / var(--context));
                    border: calc(1.5vw / var(--context)) solid rgba(255, 255, 255, 0.08);
                    margin-bottom: calc(16vw / var(--context));
                    outline: 0;
                    font-size: calc(16vw / var(--context));
                    font-weight: 400;
                    line-height: 1.75;
                    opacity: .8;
                    color: $color-white;
                }

                .enquiry-btn {
                    margin-top: calc(40vw / var(--context));
                }

                p {
                    margin-top: calc(28vw / var(--context));
                    color: $color-white;
                    font-size: calc(18vw / var(--context));
                    font-weight: 500;
                    line-height: 1.77;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin-left: calc(10vw / var(--context));
                    }
                }
            }
        }
    }
}

// Enquiry Section Ends here