.generic-content-box-sec {
    .inner-wrapper {
        max-width: calc(1088vw / var(--context));
        width: 100%;
        margin: 0 auto;
        @include breakpoint-down(xlarge) {
            max-width: calc(1350vw / var(--context));
        }
        @include breakpoint-down(xmedium) {
            max-width: 100%;
        }
    }
    article {
        width: 100%;
        display: inline-block;
    }
}

.visual-editor-content {
    .post-date {
        color: #9B9B9B;
        margin-bottom: calc(5vw / var(--context));
    }
    .entry-header {
        margin-bottom: calc(35vw / var(--context));
        @include breakpoint-down(small) {
            margin-bottom: calc(20vw / var(--context));
        }
    }
    .post-thumbnail {
        margin-bottom: calc(75vw / var(--context));
        @include breakpoint-down(xmedium) {
            margin-bottom: calc(55vw / var(--context));
        }
        @include breakpoint-down(small) {
            margin-bottom: calc(35vw / var(--context));
        }
        img {
            width: 100%;
            height: auto;
            margin: 0;
        }
    }
    hr {
        background-color: $color-black;
        border: 0;
        height: calc(1vw / var(--context));
        margin: 0 0 calc(40vw / var(--context));
    }
    .cust-vid-wrap-outer {
        
    }
    .cust-vid-wrap {
        position: relative;
        padding: 56.25% 0 0;
        iframe,
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
    iframe {
        width: 100%;
        margin: calc(30vw / var(--context)) 0;
    }
    h1,h2,h3,h4,h5,h6 {
        margin: calc(10vw / var(--context)) 0;
    }
    figcaption {
        font-size: calc(18vw / var(--context));
        font-weight: $bold;
        line-height: 1.65;
    }
    ul,
    ol {
        padding: 0 0 calc(35vw / var(--context));
        list-style-position: outside;
        margin-left: calc(20vw / var(--context));
        li {
            margin-bottom: calc(10vw / var(--context));
            line-height: 1.65;
        }
    }
    ul {
        list-style-type: disc;
    }
    blockquote {
        margin: calc(60vw / var(--context)) 0;
        @include breakpoint-down(small) {
            margin: calc(40vw / var(--context)) 0;
        }
    }
    blockquote,
    blockquote p {
        @extend .h3;
    }
    figure {
        max-width: 100%;
        img {
            margin: 0;
        }
        &.alignright {
            float:right; 
            margin:calc(40vw / var(--context)) 0 calc(40vw / var(--context)) calc(50vw / var(--context));
            @include breakpoint-down(xmedium) {
                float: none;
                display: block;
                margin: calc(20vw / var(--context)) 0 calc(30vw / var(--context)) 0 calc(20vw / var(--context));
            }
        }
        &.alignleft {
            display: block;
            float:left; 
            margin: calc(40vw / var(--context)) calc(50vw / var(--context)) calc(40vw / var(--context)) 0;
            @include breakpoint-down(xmedium) {
                float: none;
                margin: calc(20vw / var(--context)) 0 calc(20vw / var(--context));
            }
        }
        &.aligncenter {
            display: block; 
            margin-left: auto; 
            margin-right: auto;
            margin-bottom: calc(40vw / var(--context));
            margin-top: calc(40vw / var(--context));
            @include breakpoint-down(xmedium) {
                margin-bottom: calc(20vw / var(--context));
                margin-top: calc(20vw / var(--context));
            }
        }
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: calc(45vw / var(--context)) 0;
        padding-top: 10px;
        .gallery-item {
            display: block;
            margin: 0 0 calc(45vw / var(--context)) calc(40vw / var(--context));
            img {
                width: 100%;
                margin: 0;
            }
        }
        &.gallery-columns-1 {
            .gallery-item {
                width: calc(100% / 1 - calc(40vw / var(--context)));
            }
        }
        &.gallery-columns-2 {
            .gallery-item {
                width: calc(100% / 2 - calc(40vw / var(--context)));
            }
        }
        &.gallery-columns-3 {
            .gallery-item {
                width: calc(100% / 3 - calc(40vw / var(--context)));
            }
        }
        &.gallery-columns-4 {
            .gallery-item {
                width: calc(100% / 4 - calc(20vw / var(--context)));
                margin: 0 0 calc(20vw / var(--context)) calc(20vw / var(--context));
            }
        }
        &.gallery-columns-5 {
            .gallery-item {
                width: calc(100% / 5 - calc(20vw / var(--context)));
                margin: 0 0 calc(20vw / var(--context)) calc(20vw / var(--context));
            }
        }
        &.gallery-columns-6 {
            .gallery-item {
                width: calc(100% / 6 - calc(15vw / var(--context)));
                margin: 0 0 calc(15vw / var(--context)) calc(15vw / var(--context));
            }
        }
        &.gallery-columns-7 {
            .gallery-item {
                width: calc(100% / 7 - calc(15vw / var(--context)));
                margin: 0 0 calc(15vw / var(--context)) calc(15vw / var(--context));
            }
        }
        &.gallery-columns-8 {
            .gallery-item {
                width: calc(100% / 8 - calc(10vw / var(--context)));
                margin: 0 0 calc(10vw / var(--context)) calc(10vw / var(--context));
            }
        }
        &.gallery-columns-9 {
            .gallery-item {
                width: calc(100% / 9 - calc(10vw / var(--context)));
                margin: 0 0 calc(10vw / var(--context)) calc(10vw / var(--context));
            }
        }
    }
    img {
        margin: calc(20vw / var(--context)) 0;
        height: auto;
        &.alignright {
            float:right; 
            margin:0 0 calc(30vw / var(--context)) calc(50vw / var(--context));
            @include breakpoint-down(xmedium) {
                display: block;
                float: none;
                margin: calc(20vw / var(--context)) 0 calc(20vw / var(--context));
            }
        }
        &.alignleft {
            float:left; 
            margin:0 calc(30vw / var(--context)) calc(50vw / var(--context)) 0;
            @include breakpoint-down(xmedium) {
                display: block;
                float: none;
                margin: calc(20vw / var(--context)) 0 calc(20vw / var(--context));
            }
        }
        &.aligncenter {
            display: block; 
            margin-left: auto; 
            margin-right: auto;
            margin-bottom: calc(30vw / var(--context));
            @include breakpoint-down(xmedium) {
                margin-bottom: calc(20vw / var(--context));
            }
        }
    }
    a:not(.btn) {
        color: $color-black;
        &:hover {
            color: $color-black;
        }
        img {
            &.alignright {
                float:right; 
                margin:0 0 calc(30vw / var(--context)) calc(50vw / var(--context));
                @include breakpoint-down(xmedium) {
                    display: block;
                    float: none;
                    margin: 0 0 calc(20vw / var(--context));
                }
            }
            &.alignleft {
                float:left; 
                margin:0 calc(30vw / var(--context)) calc(50vw / var(--context)) 0;
                @include breakpoint-down(xmedium) {
                    display: block;
                    float: none;
                    margin: 0 0 calc(20vw / var(--context));
                }
            }
            &.aligncenter {
                display: block; 
                margin-left: auto; 
                margin-right: auto;
                margin-bottom: calc(30vw / var(--context));
                @include breakpoint-down(xmedium) {
                    margin-bottom: calc(20vw / var(--context));
                }
            }
        }
    }

    .entry-footer {
        display: none;
    }
    .btn-box {
        padding-top: calc(20vw / var(--context));
    }
}