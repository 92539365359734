.our-pricing {
    padding-top: calc(128vw / var(--context));

    @include breakpoint-down(small) {
        padding-top: calc(50vw / var(--context));
    }

    .our-pricing-title {
        max-width: calc(562vw / var(--context));
        width: 100%;
        margin: 0 auto;

        h2 {
            color: $color-peacoat;
            font-weight: 600;
            line-height: 1.12;
            margin-bottom: 0;
            text-align: center;
        }

        p {
            margin-top: calc(16vw / var(--context));
            margin-bottom: 0;
            color: $color-peacoat;
            font-size: calc(16vw / var(--context));
            font-weight: 500;
            opacity: 0.7;
            line-height: 1.75;
            text-align: center;
        }
    }

    .our-pricing-wrapper {
        margin: calc(64vw/ var(--context));

        .our-pricing-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin-left: calc(-32vw / var(--context));
            margin-right: calc(-32vw / var(--context));

            @include breakpoint-down(small) {
                flex-direction: column;
                gap: calc(20vw / var(--context));
            }

            .card {
                width: calc(33.33% - 32vw / var(--context));
                background-color: $color-grey;
                padding: calc(64vw / var(--context)) calc(77vw / var(--context)) calc(62vw / var(--context)) calc(48vw / var(--context));
                border-radius: calc(12vw / var(--context));
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                @include breakpoint-down(small) {
                    width: 100%;
                }

                h3 {
                    color: $color-peacoat;
                    font-weight: 600;
                    line-height: 1.47;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: calc(16vw/ var(--context));
                        font-family: 400;
                        color: $color-blue;
                        line-height: 1.75;
                        text-transform: capitalize;
                        margin-left: calc(10vw / var(--context));
                    }
                }

                h5 {
                    color: $color-peacoat;
                    font-weight: 500;
                    margin-bottom: 0;
                    line-height: 1.5;
                    margin-bottom: 0;
                    margin-top: calc(20vw / var(--context));
                }

                p {
                    color: $color-peacoat;
                    font-size: calc(16vw / var(--context));
                    font-weight: 500;
                    line-height: 1.75;
                    margin-bottom: 0;
                    margin-top: calc(8vw / var(--context));
                }

                ul {
                    margin-top: calc(50vw / var(--context));

                    li {
                        display: flex;
                        align-items: center;
                        font-size: calc(16vw / var(--context));
                        font-weight: 400;
                        line-height: 1.75;
                        color: $color-peacoat;
                        margin-bottom: calc(20vw / var(--context));

                        &.feature-not-included {
                            opacity: .7;
                        }

                        img {
                            width: calc(26vw / var(--context));
                            margin-right: calc(12vw / var(--context));
                        }
                    }
                }

                .primary-btn {
                    background-color: $color-black;
                    color: $color-white;
                    display: block;
                    margin: 0 auto;
                    margin-top: calc(40vw / var(--context));
                    padding: calc(16vw / var(--context)) calc(70vw / var(--context));
                }

                &:nth-child(2) {
                    background-color: $color-space;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        top: -12%;
                        left: -50%;
                        height: 705px;
                        background: rgba(255, 255, 255, 0.06);
                        width: 650px;
                        z-index: -1;
                        border-radius: 50%;

                        @include breakpoint-down(small) {
                            top: -25%;
                            left: -10%;
                            height: 750px;
                            width: 750px;
                        }
                    }

                    h3 {
                        color: $color-white !important;

                        span {
                            color: $color-jasmine;
                        }
                    }

                    h5 {
                        color: $color-white;
                    }

                    p {
                        color: $color-white;
                        opacity: 0.7;
                    }

                    ul {
                        li {
                            color: $color-white;
                        }
                    }

                    .primary-btn {
                        background-color: $color-jasmine;
                        color: $color-peacoat;
                    }
                }
            }
        }
    }
}