.contact {
    padding: calc(128vw / var(--context)) 0;
    
    @include breakpoint-down(small){
        padding: calc(50vw / var(--context)) 0;
    }

    .contact-title {
        max-width: calc(562vw / var(--context));
        width: 100%;
        margin: 0 auto;

        h2 {
            text-align: center;
            color: $color-peacoat;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1.33;
        }

        p {
            color: $color-peacoat;
            font-size: calc(16vw /var(--context));
            font-weight: 400;
            margin-top: calc(16vw / var(--context));
            margin-bottom: 0;
            line-height: 1.75;
            opacity: .7;
            text-align: center;
        }
    }

    .contact-form {
        background-color: $color-grey;
        padding: calc(55VW / var(--context)) calc(77VW / var(--context)) calc(69VW / var(--context));
        margin-top: calc(64vw / var(--context));
        border-radius: calc(12vw / var(--context));
        @include breakpoint-down(small){
            padding: calc(50vw / var(--context));
        }
        .contact-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include breakpoint-down(small){
                flex-direction: column;
                gap: calc(20vw / var(--context));
            }

            &.subject-row {
                margin-top: calc(24vw / var(--context));
            }

            .name,
            .email,
            .subject,
            .service {
                width: calc(50% - 16vw / var(--context));
                @include breakpoint-down(small){
                    width: 100%;
                }
            }

            .name,
            .email,
            .subject,
            .service {
                display: flex;
                flex-direction: column;
                // @include breakpoint-down(small){
                //     gap: calc(20vw / var(--context));
                // }

                label {
                    margin-bottom: calc(10vw / var(--context));
                    display: block;
                }

                input {
                    padding: calc(18vw / var(--context)) 0 calc(18vw / var(--context)) calc(32vw / var(--context));
                    background-color: transparent;
                    border: calc(1vw / var(--context)) solid rgba(0, 0, 0, 0.12);
                    border-radius: calc(8vw / var(--context));
                }
                ::placeholder{
                    color: $color-black;
                }
            }

        }

        .message {
            display: flex;
            flex-direction: column;
            margin-top: calc(16vw / var(--context));
            label {
                margin-bottom: calc(10vw / var(--context));
                display: block;
            }

            textarea {
                padding: calc(18vw / var(--context)) 0 calc(18vw / var(--context)) calc(32vw / var(--context));
                background-color: transparent;
                border: calc(1vw / var(--context)) solid rgba(0, 0, 0, 0.12);
                border-radius: calc(8vw / var(--context));
                color: $color-black;
            }
        }
        .primary-btn{
            background-color: $color-black;
            color: $color-white;
            margin-top: calc(32vw / var(--context));

            &:hover{
                background-color: $color-jasmine;
                color: $color-black;
            }
        }
        

    }
}