.client-benefits {

    .client-benefits-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint-down(small) {
            flex-direction: column;
            padding: calc(30vw / var(--context));
        }

        .client-benefits-left {
            width: 49.14%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            span {
                color: $color-black;
                font-size: calc(18vw / var(--context));
                font-weight: 500;
                line-height: 1.77;
                opacity: 0.87;
            }

            h3 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.47;
                margin-bottom: 0;
                padding-top: calc(16vw /var(--context));

            }

            p {
                color: $color-peacoat;
                font-weight: 400;
                line-height: 1.75;
                margin-bottom: 0;
                padding-top: calc(16vw /var(--context));
            }
        }

        .client-benefits-right {
            width: 50%;

            @include breakpoint-down(small) {
                width: 100%;
            }


            img {
                max-width: calc(575vw / var(--context));
                width: 100%;
                display: block;
                margin: 0 auto;
                @include breakpoint-down(small) {
                    padding-top: calc(32vw / var(--context));
                }
            }
        }
    }

    .client-benefits-wrapper-background {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: $color-grey;
        padding: calc(128vw / var(--context)) calc(160vw / var(--context));
        margin: calc(128vw / var(--context)) 0;
        gap: calc(40vw / var(--context));
        
        @include breakpoint-down(small) {
            flex-direction: column;
            padding: calc(50vw / var(--context));
            margin: calc(50vw / var(--context)) 0;
        }

        &.client-row-jasmine-background {
            background-color: $color-jasmine;
        }

        .client-benefits-left {
            width: 49.14%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            span {
                color: $color-black;
                font-size: calc(18vw / var(--context));
                font-weight: 500;
                line-height: 1.77;
                opacity: 0.87;
            }

            h3 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.47;
                margin-bottom: 0;
                padding-top: calc(16vw /var(--context));

            }

            p {
                color: $color-peacoat;
                font-weight: 400;
                line-height: 1.75;
                margin-bottom: 0;
                padding-top: calc(16vw /var(--context));
            }
        }

        .client-benefits-right {
            width: 45.3%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            img {
                max-width: calc(575vw / var(--context));
                width: 100%;
                // @include breakpoint-down(small) {
                //     padding-top: calc(32vw / var(--context));
                // }
            }
        }
    }
}