/* section spacer xmini */
.py-xmini {
    padding-top: calc(16vw / var(--context));
    padding-bottom: calc(16vw / var(--context));
}
.pt-xmini {
    padding-top: calc(16vw / var(--context));
}
.pb-xmini {
    padding-bottom: calc(16vw / var(--context));
}

.my-xmini {
    margin-top: calc(16vw / var(--context));
    margin-bottom: calc(16vw / var(--context));
}
.mt-xmini {
    margin-top: calc(16vw / var(--context));
}
.mb-xmini {
    margin-bottom: calc(16vw / var(--context));
}

/* section spacer mini */
.py-mini {
    padding-top: calc(24vw / var(--context));
    padding-bottom: calc(24vw / var(--context));
}
.pt-mini {
    padding-top: calc(24vw / var(--context));
}
.pb-mini {
    padding-bottom: calc(24vw / var(--context));
}

.my-mini {
    margin-top: calc(24vw / var(--context));
    margin-bottom: calc(24vw / var(--context));
}
.mt-mini {
    margin-top: calc(24vw / var(--context));
}
.mt-mini {
    margin-bottom: calc(24vw / var(--context));
}

/* section spacer xxs */
.py-xxs {
    padding-top: calc(36vw / var(--context));
    padding-bottom: calc(36vw / var(--context));
}
.pt-xxs {
    padding-top: calc(36vw / var(--context));
}
.pb-xxs {
    padding-bottom: calc(36vw / var(--context));
}

.my-xxs {
    margin-top: calc(36vw / var(--context));
    margin-bottom: calc(36vw / var(--context));
}
.mt-xxs {
    margin-top: calc(36vw / var(--context));
}
.mb-xxs {
    margin-bottom: calc(36vw / var(--context));
}

/* section spacer xs */
.py-xs {
    padding-top: calc(45vw / var(--context));
    padding-bottom: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(38vw / var(--context));
        padding-bottom: calc(38vw / var(--context));
    }
}
.pt-xs {
    padding-top: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(38vw / var(--context));
    }
}
.pb-xs {
    padding-bottom: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(38vw / var(--context));
    }
}

.my-xs {
    margin-top: calc(45vw / var(--context));
    margin-bottom: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(38vw / var(--context));
        margin-bottom: calc(38vw / var(--context));
    }
}
.mt-xs {
    margin-top: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(38vw / var(--context));
    }
}
.mb-xs {
    margin-bottom: calc(45vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(38vw / var(--context));
    }
}

/* section spacer sm */
.py-sm {
    padding-top: calc(60vw / var(--context));
    padding-bottom: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(40vw / var(--context));
        padding-bottom: calc(40vw / var(--context));
    }
}
.pt-sm {
    padding-top: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(40vw / var(--context));
    }
}
.pb-sm {
    padding-bottom: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(40vw / var(--context));
    }
}

.my-sm {
    margin-top: calc(60vw / var(--context));
    margin-bottom: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(40vw / var(--context));
        margin-bottom: calc(40vw / var(--context));
    }
}
.mt-sm {
    margin-top: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(40vw / var(--context));
    }
}
.mb-sm {
    margin-bottom: calc(60vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(40vw / var(--context));
    }
}

/* section spacer md */
.py-md {
    padding-top: calc(80vw / var(--context));
    padding-bottom: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(50vw / var(--context));
        padding-bottom: calc(50vw / var(--context));
    }
}
.pt-md {
    padding-top: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(50vw / var(--context));
    }
}
.pb-md {
    padding-bottom: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(50vw / var(--context));
    }
}

.my-md {
    margin-top: calc(80vw / var(--context));
    margin-bottom: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(50vw / var(--context));
        margin-bottom: calc(50vw / var(--context));
    }
}
.mt-md {
    margin-top: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(50vw / var(--context));
    }
}
.mb-md {
    margin-bottom: calc(80vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(50vw / var(--context));
    }
}
/* section spacer standard */
.py {
    padding-top: calc(100vw / var(--context));
    padding-bottom: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(60vw / var(--context));
        padding-bottom: calc(60vw / var(--context));
    }
}
.pt {
    padding-top: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(60vw / var(--context));
    }
}
.pb {
    padding-bottom: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(60vw / var(--context));
    }
}

.my {
    margin-top: calc(100vw / var(--context));
    margin-bottom: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(60vw / var(--context));
        margin-bottom: calc(60vw / var(--context));
    }
}
.mt {
    margin-top: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(60vw / var(--context));
    }
}
.mb {
    margin-bottom: calc(100vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(60vw / var(--context));
    }
}

/* section spacer lg */
.py-lg {
    padding-top: calc(130vw / var(--context));
    padding-bottom: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(80vw / var(--context));
        padding-bottom: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(60vw / var(--context));
        padding-bottom: calc(60vw / var(--context));
    }
}
.pt-lg {
    padding-top: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(60vw / var(--context));
    }
}
.pb-lg {
    padding-bottom: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-bottom: calc(60vw / var(--context));
    }
}

.my-lg {
    margin-top: calc(130vw / var(--context));
    margin-bottom: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(80vw / var(--context));
        margin-bottom: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(60vw / var(--context));
        margin-bottom: calc(60vw / var(--context));
    }
}
.mt-lg {
    margin-top: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(60vw / var(--context));
    }
}
.mb-lg {
    margin-bottom: calc(130vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(80vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-bottom: calc(60vw / var(--context));
    }
}

/* section spacer xl */
.py-xl {
    padding-top: calc(155vw / var(--context));
    padding-bottom: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(90vw / var(--context));
        padding-bottom: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(70vw / var(--context));
        padding-bottom: calc(70vw / var(--context));
    }
}
.pt-xl {
    padding-top: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(70vw / var(--context));
    }
}
.pb-xl {
    padding-bottom: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-bottom: calc(70vw / var(--context));
    }
}

.my-xl {
    margin-top: calc(155vw / var(--context));
    margin-bottom: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(90vw / var(--context));
        margin-bottom: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(70vw / var(--context));
        margin-bottom: calc(70vw / var(--context));
    }
}
.mt-xl {
    margin-top: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(70vw / var(--context));
    }
}
.mb-xl {
    margin-bottom: calc(155vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(90vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-bottom: calc(70vw / var(--context));
    }
}

/* section spacer xxl */
.py-xxl {
    padding-top: calc(180vw / var(--context));
    padding-bottom: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(100vw / var(--context));
        padding-bottom: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(80vw / var(--context));
        padding-bottom: calc(80vw / var(--context));
    }
}
.pt-xxl {
    padding-top: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(80vw / var(--context));
    }
}
.pb-xxl {
    padding-bottom: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-bottom: calc(80vw / var(--context));
    }
}

.my-xxl {
    margin-top: calc(180vw / var(--context));
    margin-bottom: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(100vw / var(--context));
        margin-bottom: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(80vw / var(--context));
        margin-bottom: calc(80vw / var(--context));
    }
}
.mt-xxl {
    margin-top: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(80vw / var(--context));
    }
}
.mb-xxl {
    margin-bottom: calc(180vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(100vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-bottom: calc(80vw / var(--context));
    }
}

/* section spacer xxxl */
.py-xxxl {
    padding-top: calc(240vw / var(--context));
    padding-bottom: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(120vw / var(--context));
        padding-bottom: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(100vw / var(--context));
        padding-bottom: calc(100vw / var(--context));
    }
}
.pt-xxxl {
    padding-top: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-top: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-top: calc(100vw / var(--context));
    }
}
.pb-xxxl {
    padding-bottom: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding-bottom: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding-bottom: calc(100vw / var(--context));
    }
}

.my-xxxl {
    margin-top: calc(240vw / var(--context));
    margin-bottom: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(120vw / var(--context));
        margin-bottom: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(100vw / var(--context));
        margin-bottom: calc(100vw / var(--context));
    }
}
.mt-xxxl {
    margin-top: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-top: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-top: calc(100vw / var(--context));
    }
}
.mb-xxxl {
    margin-bottom: calc(240vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-bottom: calc(120vw / var(--context));
    }
    @include breakpoint-down(small) {
        margin-bottom: calc(100vw / var(--context));
    }
}

/* ngative margin */
.mt-n-lg {
    margin-top: calc(-100vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-top: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-top: 0;
    }
}
.mb-n-lg {
    margin-bottom: calc(-100vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-bottom: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-bottom: 0;
    }
}
.mt-n-xl {
    margin-top: calc(-160vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-top: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-top: 0;
    }
}
.mb-n-xl {
    margin-bottom: calc(-160vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-bottom: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-bottom: 0;
    }
}
.mt-n-xxl {
    margin-top: calc(-200vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-top: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-top: 0;
    }
}
.mb-n-xxl {
    margin-bottom: calc(-200vw / var(--context));
    @include breakpoint-down(xlarge) {
        margin-bottom: calc(-80vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        margin-bottom: 0;
    }
}


/* reset spacing to zero */
.mb-0 {
    margin-bottom: 0;
}
.mt-0 {
    margin-top: 0;
}
.pb-0 {
    padding-bottom: 0;
}
.pt-0 {
    padding-top: 0;
}