
  
  .loader-wrapper {
    width: 100%;
    height: 100vh;
    background: #232831;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .loader {
      width: 100px;
      height: 100px;
      background: linear-gradient(45deg, transparent, transparent 40%, #f48403);
      border-radius: 50%;
      position: relative;
      animation: loader 1s linear infinite;
  
      &::before {
        position: absolute;
        content: "";
        top: 3px;
        right: 3px;
        left: 3px;
        bottom: 3px;
        background: #232831;
        border-radius: 50%;
        z-index: 1;
      }
  
      &:after {
        position: absolute;
        content: "";
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background: linear-gradient(45deg, transparent, transparent 40%, #f48403);
        border-radius: 50%;
        filter: blur(20px);
      }
    }
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
      filter: hue-rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      filter: hue-rotate(360deg);
    }
  }
  