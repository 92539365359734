* {
    padding: 0px;
    margin: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
:root {
    --context: 16;
    --context-height: 16;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-60% + var(--offset));
    @include breakpoint-down(xmedium) {
    --context: 14.4;
    }
    @include breakpoint-down(small) {
        --context: 3.75;
    }
}
html {
    overflow-x: hidden;
    &.scrollLock {
        overflow: hidden;
    }
}
body.home {
    padding-top: 0;
}
body {
    -webkit-text-size-adjust: none;
    position: relative;
    width: 100%;
    @include breakpoint-down(large) {
        // padding-top: calc(108vw / var(--context));
    }
    @include breakpoint-down(xmedium) {
        // padding-top: calc(58vw / var(--context));
    }
}

#page {
    overflow: hidden;
}

main.site-main {
    min-height: 75vh;
}

figure,ul{
    margin: 0;
}
button {
    background-color: transparent;
    border: 0;
    outline: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
// Added to offset the margin on <html> for a logged in WP user
.admin-bar {
	// margin-top: 32px;

	// .site {
	// 	padding-top: 32px;
	// }

	@media screen and (max-width: 782px) {
		margin-top: -46px;

		.site {
			padding-top: 46px;
		}
	}
}
.container{
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 calc(160vw / var(--context));
    @include breakpoint-down(xmedium) {
        padding: 0 calc(40vw / var(--context));
    }
    @include breakpoint-down(small) {
        padding: 0 calc(20vw / var(--context));
    }
}

.touch-vp-right {
    margin-right: calc(-140vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-right: 0;
    }
}
.touch-vp-left {
    margin-left: calc(-140vw / var(--context));
    @include breakpoint-down(xmedium) {
        margin-left: 0;
    }
}

img {
    max-width: 100%;
    vertical-align: top;
    &.img-full {
        width: 100%;
    }
}

iframe,
video {
    max-width: 100%;
}

.aos-init.aos-animate {
    @include breakpoint-down(xmedium) {
        transition-delay: 0s!important;
    }
}

.color-white {
    * {
        color: $color-white;
    }
}

.bg-control {
    background-size: cover;
    background-repeat: no-repeat;
}

.slick-slide {
    outline: none;
}

input[type=submit]{
    -webkit-appearance: none;
    appearance: none;
}
button,div,a{
    outline: 0px;
    &:focus{
        outline: 0px;
    }
}
.slick-slider .slick-track,[data-aos],.slick-slider .slick-list,.swiper-wrapper,.swiper-slide,.swiper-container{
    will-change: transform;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button{
    cursor: pointer;
    -webkit-appearance: none;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: none;
    width: 0;
}
::-webkit-scrollbar-thumb {
    background: $color-black;
    border-radius: 10px;
}