// Breakpoint
$xsmall: 480px !default;
$small: 750px !default;
$xmedium: 992px !default;
$large: 1200px !default;
$xlarge: 1600px !default;

// column width
$grid-width: 8.333333333333334%;

// Font Family
$font-def:'Poppins', sans-serif;
$font-manrope:'Manrope', sans-serif;

// Font Weight
$thin: 100;
$x-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$s-bold: 600;
$bold: 700;
$x-bold: 800;
$black: 900;

//Font Style
$italic: italic;


// Colors
$color-black: #212121;
$color-white: #fff;
$color-space:#1C1E53;
$color-delft:#383A68;
$color-eigen:#1B1C2B;
$color-jasmine:#FCD980;
$color-grey:#F4F6FC;
$color-accent:#EEF4FA;
$color-smoke:#F4F5F5;
$color-peacoat:#282938;
$color-blue:#2405F2;

// Breakpoints
$breakpoints: (xsmall: $xsmall, small: $small, xmedium: $xmedium, large: $large, xlarge: $xlarge);
