.header {
    background-color: $color-space;
    padding: calc(33vw / var(--context)) 0;
    position: relative;
    @include breakpoint-down(small) {
        padding: 0;
    }

    .header-wrapper {
        .header-left {
            width: 30%;
            img{
                width: 60%;
            }
            h1 {
                color: $color-white;
                margin-bottom: 0;
            }
        }

        .header-right {
            width: 70%;

            .humberger {
                display: none;

                @include breakpoint-down(xlarge) {
                    display: none;
                }

                @include breakpoint-down(small) {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-direction: column;
                    padding-top: calc(15vw / var(--context));
                    position: relative;
                    z-index: 999;
                }

                .open {
                    position: relative;

                    .line {
                        cursor: pointer;
                        display: block;
                        background: $color-white;
                        height: calc(2vw / var(--context));
                        width: calc(30vw / var(--context));
                        border-radius: calc(10vw / var(--context));
                        margin-bottom: calc(10vw /var(--context));

                        &:last-child(1) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .close {
                position: relative;
                right: calc(30vw / var(--context));
                top: calc(-9vw / var(--context));

                span {
                    display: block;
                    background: $color-white;
                    height: calc(2vw / var(--context));
                    width: calc(30vw / var(--context));
                    border-radius: calc(10vw / var(--context));
                    margin-bottom: calc(10vw /var(--context));

                    &:first-child {
                        -webkit-transform: rotate(135deg);
                        transform: rotate(135deg);
                        -webkit-transform-origin: center;
                        transform-origin: center;
                        position: absolute;
                    }

                    &:last-child {
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        -webkit-transform-origin: center;
                        transform-origin: center;
                        position: absolute;
                    }
                }
            }

            ul {
                position: relative;
                gap: calc(32vw / var(--context));
                align-items: center;
                @include breakpoint-down(small) {

                    display: flex;
                    flex-direction: column;
                    justify-content: unset;
                    align-items: unset;
                    width: 100%;
                    height: auto;
                    position: absolute;
                    left: 0;
                    padding-left: calc(20vw / var(--context));
                    background-color: $color-space;
                    z-index: 99;
                    gap: calc(16vw / var(--context));
                    transform: translateY(-150%);
                    li:first-child {
                        margin-top: calc(16vw / var(--context));
                    }
                    
                    li:last-child {
                        margin-bottom: calc(16vw / var(--context));

                        a {
                            border: unset;
                            padding: unset;
                        }
                    }
                }

                li {
                    * {
                        color: $color-white;
                        font-weight: 500;
                        font-size: calc(16vw / var(--context));
                        line-height: 1.75;

                        @include breakpoint-down(small) {
                            color: $color-white;
                        }
                    }

                    // &:nth-last-child(1) {
                    //     * {
                    //         border: calc(2vw / var(--context)) solid rgba(244, 246, 252, 0.20);
                    //         border-radius: calc(41vw / var(--context));
                    //         padding: calc(16vw / var(--context)) calc((48vw / var(--context)));
                    //     }
                    // }
                }

            }
        }
    }
}