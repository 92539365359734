.form-group{
    position: relative;
    margin-bottom: calc(35vw / var(--context));

    label{
        margin-bottom: calc(10vw / var(--context));
        display: block;
        width: 100%;
    }
    .form-control{
        position: relative;
        z-index: 9;
        background-color: transparent;
        border: 0px;
        border-bottom: calc(1vw / var(--context)) solid;
        height: auto;
        line-height: 1.5;
        padding-bottom: calc(20vw / var(--context));
        font-size: calc(18vw / var(--context));
        font-weight: $normal;
        display: block;
        width: 100%;
        @include breakpoint-down(xlarge) {
            font-size: 16px;
        }
        &.hasIcon {
            // background-image: url(../images/search-icon.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - calc(20vw / var(--context))) center;
            padding-right: calc(45vw / var(--context))!important;
            @include breakpoint-down(small) {
                background-position: calc(100% - calc(10vw / var(--context))) center;
                background-size: 12px;
                padding-right: calc(30vw / var(--context))!important;
            }
        }
        &:focus{
            background-color: transparent;
            outline: none;
        }
        &::-webkit-input-placeholder {
            color: #1D1D1D;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: #1D1D1D;
            opacity: 1;
        }

        &::placeholder {
            color: #1D1D1D;
            opacity: 1;
        }
    }
    select.form-control{
        -webkit-appearance: none;
        -moz-appearance: none;
        // background-image: url(../images/arrow-down-icon.svg);
        background-repeat: no-repeat;
        background-position: 100% center;
        background-size: 18px;
        cursor: pointer;
        @include breakpoint-down(small) {
            background-size: 10px;
        }
    }
    textarea.form-control {
        resize: vertical;
    }
}

input[type="submit"] {
    cursor: pointer;
}

.action-group {
    margin-top: 26px;
    .cta-btn {
        font-size: 12px;
        margin-left: 12px;
    }
}

.custom-check-box {
    display: inline-block;
    position: relative;
    height: 16px;
    @include breakpoint-down(small) {
        height: 12px;
    }
    input[type="checkbox"] {
        cursor: pointer;
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        opacity: 0;
        z-index: 1;
        @include breakpoint-down(small) {
            width: 12px;
            height: 12px;
        }
        &:checked {
            + .icon {
                // background-image: url(../images/check-box-cheked.svg);
            }
        }
    }
    .icon {
        // background-image: url(../images/check-box-uncheked.svg);
        background-size: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        @include breakpoint-down(small) {
            background-size: 12px;
            width: 12px;
            height: 12px;
        }
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}