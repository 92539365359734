@keyframes boxFadeIn {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes boxFadeUp {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        display: block;
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial),0,0);
    }
    
    100% {
        transform: translate3d(var(--move-final),0,0);
    }
}