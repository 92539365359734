.about-hero {
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;

    }

    .about-hero-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint-down(small) {
            flex-direction: column;

        }

        .about-hero-left {
            width: 44%;

            @include breakpoint-down(small) {
                width: 100%;

            }

            span {
                color: $color-peacoat;
                font-weight: 500;
                margin-bottom: 0;
                line-height: 1.77;
                font-size: calc(18vw / var(--context));

            }

            h1 {
                margin-top: calc(8vw / var(--context));
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.37;
                margin-bottom: 0;
            }

            p {
                font-size: calc(16vw /var(--context));
                color: $color-peacoat;
                text-align: justify;
                font-weight: 400;
                line-height: 1.75;
                margin-bottom: 0;
                margin-top: calc(31vw / var(--context));
                max-width: calc(484vw / var(--context));
                width: 100%;
            }
        }

        .about-hero-right {
            width: 50%;
            display: flex;
            justify-content: flex-end;

            @include breakpoint-down(small) {
                width: 100%;

            }

            img {
                max-width: calc(547vw / var(--context));
                width: 100%;
                height: 100%;

                @include breakpoint-down(small) {
                    padding-top: calc(20vw / var(--context));

                }
            }
        }
    }
}

// About Hero section Ends here

// Who we are section start here

.who-we-are {
    width: 100%;

    img {
        width: 100%;
        height: 100%;
    }

    .who-we-are-wrapper {
        background-color: $color-smoke;
        padding: calc(80vw / var(--context));

        @include breakpoint-down(small) {
            padding: calc(50vw / var(--context));
        }


        .who-we-are-title {
            color: $color-peacoat;
            font-size: calc(16vw / var(--context));
            font-weight: 500;
            line-height: 1.75;
            margin-bottom: 0;
        }

        .who-we-are-inner-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .who-we-are-left {
                width: 46%;

                @include breakpoint-down(small) {
                    width: 100%;
                }

                h3 {
                    color: $color-peacoat;
                    font-weight: 600;
                    line-height: 1.47;
                    margin-bottom: 0;
                }

                p {
                    color: $color-peacoat;
                    font-weight: 400;
                    font-size: calc(16vw / var(--context));
                    margin-bottom: 0;
                    margin-top: calc(16vw / var(--context));
                }
            }

            .who-we-are-right {
                width: 46%;

                @include breakpoint-down(small) {
                    width: 100%;
                }

                h3 {
                    color: $color-peacoat;
                    font-weight: 600;
                    line-height: 1.47;
                    margin-bottom: 0;
                }

                p {
                    color: $color-peacoat;
                    font-weight: 400;
                    font-size: calc(16vw / var(--context));
                    margin-bottom: 0;
                    margin-top: calc(16vw / var(--context));
                }
            }

        }
    }
}

// Who we are section start here

// Our Process section start here

.about-process {
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    h2 {
        color: $color-peacoat;
        font-weight: 600;
        line-height: 1.33;
        margin-bottom: 0;
        text-align: center;
    }

    .about-process-wrapper {
        margin-top: calc(48vw / var(--context));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        .col {
            width: calc(26.1% - 56vw / var(--context));

            @include breakpoint-down(small) {
                width: 50%;

                &:nth-child(3) {
                    padding-top: calc(32vw / var(--context));
                }

                &:nth-child(4) {
                    padding-top: calc(32vw / var(--context));
                }
            }

            .card {
                span {
                    width: calc(24vw / var(--context));
                    height: calc(24vw / var(--context));
                    background: $color-blue;
                    border-radius: 50%;
                    display: block;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: calc(200vw /var(--context));
                        margin-left: calc(40vw / var(--context));
                        margin-top: calc(10vw / var(--context));
                        border-top: calc(2vw / var(--context)) dashed $color-blue;
                        opacity: 0.4;
                        @include breakpoint-down(small){
                            width: calc(150vw / var(--context));
                        }
                    }
                }

                h5 {
                    color: $color-peacoat;
                    font-weight: 500;
                    line-height: 1.5;
                    margin-bottom: 0;
                    padding-top: calc(32vw / var(--context));

                }

                p {

                    color: $color-peacoat;
                    font-weight: 400;
                    font-size: calc(16vw / var(--context));
                    line-height: 1.75;
                    margin-bottom: 0;
                    padding-top: calc(8vw / var(--context));
                }
            }
        }
    }
}

// Our Process section ends here

// Our Mission section start here

.our-mission {
    background-color: $color-accent;
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding-top: calc(18vw / var(--context));
        padding-bottom: calc(50vw / var(--context));
    }

    .our-mission-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint-down(small) {
            flex-direction: column;
        }

        .our-mission-left {
            width: 50%;

            @include breakpoint-down(small) {
                width: 100%;
                padding-top: calc(32vw / var(--context));
            }

            span {
                color: $color-peacoat;
                font-size: calc(16vw / var(--context));
                font-weight: 500;
                line-height: calc(1.75);
            }

            h3 {
                color: $color-peacoat;
                font-weight: 600;
                line-height: 1.47;
                margin-bottom: 0;
                padding: calc(16vw / var(--context)) 0;

                @include breakpoint-down(small) {
                    padding-top: calc(8vw / var(--context));
                }
            }

            p {
                color: $color-peacoat;
                font-weight: 400;
                font-size: calc(16vw / var(--context));
                line-height: 1.75;
                margin-bottom: 0;
            }
        }

        .our-mission-right {
            width: 50%;

            @include breakpoint-down(small) {
                width: 100%;
            }

            img {
                max-width: calc(515vw / var(--context));
                width: 100%;
            }

            &.mission-img {
                display: flex;
                justify-content: flex-end;

                @include breakpoint-down(small) {
                    justify-content: flex-start;
                    padding-top: calc(32vw / var(--context));
                }
            }

            &.vision-img {
                margin-top: calc(96vw / var(--context));

                @include breakpoint-down(small) {
                    margin-top: calc(32vw / var(--context));
                }
            }
        }
    }
}

// Our Mission section ends here

// Benefits section Start here

.benefits {
    padding: calc(128vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    h2 {
        color: $color-peacoat;
        font-weight: 600;
        line-height: 1.33;
        text-align: center;
        max-width: calc(630vw / var(--context));
        width: 100%;
        margin: 0 auto;
    }

    .benefits-wrapper {
        margin-top: calc(48vw / var(--context));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        @include breakpoint-down(small) {
            flex-direction: column;
            gap:calc(20vw / var(--context));
        }


        .card {
            width: calc(33.33% - 32vw / var(--context));
            background: $color-grey;
            padding: calc(52vw / var(--context)) calc(32vw / var(--context)) calc(48vw / var(--context)) calc(48vw / var(--context));
            @include breakpoint-down(small) {
                width: 100%;
            }

            img {
                width: calc(32vw / var(--context));
                height: calc(32vw / var(--context));
            }

            h5 {
                color: $color-peacoat;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 0;
                margin-top: calc(26vw / var(--context));
                margin-bottom: calc(12vw / var(--context));
            }

            p {
                color: $color-peacoat;
                font-family: calc(16vw / var(--context));
                font-weight: 400;
                line-height: 1.75;
                margin-bottom: 0;
            }
        }
    }
}

// Benefits section Ends here

// Team section start here

.team {
    background-color: $color-grey;
    padding: calc(128vw / var(--context)) 0;
    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
    }

    h2 {
        color: $color-peacoat;
        font-weight: 600;
        line-height: 1.33;
        text-align: center;
        margin-bottom: 0;
    }

    .team-wrapper {
        padding-top: calc(48vw / var(--context));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        @include breakpoint-down(small){
        flex-direction: column;
        gap: calc(20vw / var(--context));
        }


        .card {
            width: calc(25% - 32vw / var(--context));
            background: $color-white;
            position: relative;
            padding: calc(48vw / var(--context)) 0 calc(32vw / var(--context)) 0;
            @include breakpoint-down(small){
                width: 100%;
                }

            img {
                display: block;
                margin: 0 auto;
                width: calc(168vw / var(--context));
                height: calc(168vw / var(--context));

                &::before {
                    content: url('../../assets/images/team-overlay.jpg');
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }

            img:hover+.user-social {
                opacity: 1;
            }

            .user-social {
                position: absolute;
                top: 50%;
                left: 50%;
                display: flex;
                justify-content: center;
                gap: calc(20vw / var(--context));
                transform: translateX(-50%);
                opacity: 0;
            }

            h5 {
                text-align: center;
                margin-bottom: 0;
                color: $color-peacoat;
                font-weight: 500;
                line-height: 1.5;
                padding-top: calc(30vw / var(--context));
            }

            p {
                text-align: center;
                margin-bottom: 0;
                font-size: calc(16vw /var(--context));
                opacity: 0.7;
                color: $color-peacoat;
                font-weight: 400;
                line-height: 1.75;
            }
        }
    }
}