@mixin primary-btn($background-color, $color){
    padding:calc(16vw / var(--context)) calc(56vw / var(--context));
    border-radius:calc(41vw / var(--context));
    background:$background-color;
    color:$color;
    font-size: calc(18vw / var(--context));
    font-weight: 600;
    line-height: 1.77;
    font-family: $font-manrope
}

.primary-btn{
    @include primary-btn($color-jasmine,$color-eigen);
}
