.post-navigation,
.posts-navigation {
    width: 100%;
    .nav-links {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: calc(50vw / var(--context));
        justify-content: space-between;
        width: 100%;
        margin-top: calc(60vw / var(--context));
        @include breakpoint-down(small) {
            margin-top: calc(35vw / var(--context));
        }
        .nav-previous a {
            &:hover {
                &::before {
                    transform: translateX(calc(-5vw / var(--context)));
                }
            }
            &:before {
                content: "<";
                display: inline-block;
                margin-right: calc(7vw / var(--context));
                transform: translateX(0);
                transition: all 0.5s;
            }
        }
        .nav-next a {
            &:hover {
                &::after {
                    transform: translateX(calc(5vw / var(--context)));
                }
            }
            &:after {
                content: ">";
                display: inline-block;
                margin-left: calc(7vw / var(--context));
                transform: translateX(0);
                transition: all 0.5s;
            }
        }
        .nav-title {
            max-width: calc(350vw / var(--context));
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: top;
            @include breakpoint-down(small) {
                display: none;
            }
        }
    }
}

.no-results {
    min-height: 60vh;
    display: flex;
    align-items: center;
}

.search-result-sec {
    .page-header {
        margin-bottom: calc(60vw / var(--context));
        @include breakpoint-down(small) {
            margin-bottom: calc(35vw / var(--context));
        }
    }
    .flex {
        column-gap: calc(40vw / var(--context));
        justify-content: space-between;
        @include breakpoint-down(small) {
            column-gap: 0;
        }
        .post {
            width: calc(50% - calc(20vw / var(--context)));
            margin-bottom: calc(60vw / var(--context));
            @include breakpoint-down(small) {
                width: 100%;
                margin-bottom: calc(40vw / var(--context));
            }
            .entry-title {
                margin-bottom: calc(10vw / var(--context));
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .post-thumbnail {
                margin: calc(20vw / var(--context)) 0;
                display: block;
                border-radius: calc(20vw / var(--context));
                aspect-ratio: 1/0.75;
                padding: calc(20vw / var(--context));
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .entry-footer {
                margin-top: calc(20vw / var(--context));
            }
        }
    }
}

 /* 404 page section */
 .error-404 {
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .page-content {
        max-width: calc(1140vw / var(--context));
        margin: 0 auto;
        padding: calc(120vw / var(--context)) calc(40vw / var(--context));
        h2 {
            margin: calc(20vw / var(--context)) 0 calc(30vw / var(--context));
        }
        .desc {
            margin-bottom: calc(30vw / var(--context));
        }
    }
  }