html,body {
    font-family: $font-def;
    line-height: 1.25;
    font-size: calc(18vw / var(--context));
    -webkit-font-smoothing: antialiased;
}
button,
input,
select,
textarea {
    font-family: $font-def;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
    font-family: $font-def;
    font-weight: $bold;
    line-height: 1.1;
    margin: 0 0 calc(20vw / var(--context));
}

h1, .h1 {
    font-size: calc(54vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(32vw / var(--context));
    }
}
h2, .h2 {
    font-size: calc(48vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(28vw / var(--context));
    }
}
h3, .h3 {
    font-size: calc(38vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(28vw / var(--context));
    }
}
h4, .h4 {
    font-size: calc(32vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(24vw / var(--context));
    }
}
h5, .h5 {
    font-size: calc(24vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(20vw / var(--context));
    }
}
h6, .h6 {
    font-size: calc(20vw / var(--context));
    @include breakpoint-down(small) {
        font-size: calc(18vw / var(--context));
    }
}

p, .p {
    line-height: 1.65;
    margin-bottom: calc(35vw / var(--context));
    font-size: calc(18vw / var(--context));
}

.body-lg {
    font-size: calc(21vw / var(--context));
    li, p {
        font-size: calc(21vw / var(--context));
    }
}
.body-sm {
    font-size: calc(15vw / var(--context));
    li, p {
        font-size: calc(15vw / var(--context));
    }
}
.body-xs {
    font-size: calc(14vw / var(--context));
    li, p {
        font-size: calc(14vw / var(--context));
    }
}

a {
    color: $color-black;
    text-decoration: none;
    transition: all .5s;
    &:hover {
        color: $color-black;
    }
    &.link {
        text-decoration: underline;
    }
}
