.privacy-policy {
    padding: calc(128vw / var(--context)) 0;

    .privacy-title {
        max-width: calc(562vw / var(--context));
        width: 100%;
        margin: 0 auto;

        h2 {
            color: $color-peacoat;
            font-weight: 600;
            line-height: 1.33;
            text-align: center;
            margin-bottom: 0;
        }

        p {
            color: $color-peacoat;
            text-align: center;
            font-size: calc(16vw / var(--context));
            font-weight: 500;
            line-height: 1.75;
            padding-top: calc(16vw / var(--context));
            margin-bottom: 0;
        }
    }

    .privacy-wrapper {
        margin-top: calc(64vw / var(--context));

        h3 {
            color: $color-peacoat;
            font-weight: 600;
            line-height: 1.47;
            margin-bottom: calc(32vw / var(--context));
        }

        p {
            color: $color-peacoat;
            font-weight: 400;
            font-size: calc(16vw / var(--context));
            line-height: 1.75;
            opacity: .7;
            margin-bottom: calc(24vw / var(--context));
        }

        ul {
            li {
                color: $color-peacoat;
                font-weight: 400;
                font-size: calc(16vw / var(--context));
                line-height: 1.75;
                opacity: .7;
                margin-bottom: calc(12vw / var(--context));
            }
        }
    }
}