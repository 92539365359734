.footer {
    background-color: $color-space;
    padding: calc(96vw / var(--context)) calc(96vw / var(--context)) 0;

    @include breakpoint-down(small) {
        padding: calc(50vw / var(--context)) 0;
        overflow: hidden;
    }

    .footer-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include breakpoint-down(small) {
            flex-direction: column;
            gap: calc(20vw / var(--context));
        }

        .footer-left {
            max-width: calc(660vw / var(--context));
            width: 100%;

            .company {
                width: calc(399vw / var(--context));

                img{
                    width: 60%;
                }

                p {
                    color: $color-white;
                    margin-top: calc(23vvw / var(--context));
                    margin-bottom: 0;
                    font-weight: 500;
                    font-size: calc(16vw / var(--context));
                    line-height: 1.75;
                    opacity: .8;
                }
            }

            .contact-details {
                background-color: $color-jasmine;
                display: flex;
                align-items: flex-end;
                padding: calc(18vw / var(--context)) 0 calc(16vw / var(--context)) calc(18vw / var(--context));
                width: calc(517vw / var(--context));
                margin-top: calc(86vw / var(--context));

                @include breakpoint-down(small) {
                    margin-top: calc(15vw / var(--context));
                    width: 100%;
                    gap: calc(10vw / var(--context));
                }

                .email {
                    width: 50%;

                    strong {
                        color: $color-peacoat;
                        font-size: calc(18vw / var(--context));
                        font-weight: 500;
                        line-height: 1.77;
                        @include breakpoint-down(small){
                            font-size: calc(16vw / var(--context));
                        }
                    }

                    p {
                        color: $color-peacoat;
                        font-size: calc(16vw/ var(--context));
                        font-weight: 400;
                        line-height: 1.75;
                        margin-bottom: 0;
                        @include breakpoint-down(small){
                            font-size: calc(14vw / var(--context));
                        }
                    }
                }

                .call {
                    width: 50%;

                    strong {
                        color: $color-peacoat;
                        font-size: calc(18vw / var(--context));
                        font-weight: 500;
                        line-height: 1.77;
                        @include breakpoint-down(small){
                            font-size: calc(16vw / var(--context));
                        }
                    }

                    p {
                        color: $color-peacoat;
                        font-size: calc(16vw/ var(--context));
                        font-weight: 400;
                        line-height: 1.75;
                        margin-bottom: 0;
                        @include breakpoint-down(small){
                            font-size: calc(14vw / var(--context));
                        }
                    }
                }
            }
        }

        .footer-right {
            width: calc(100% - 660vw / var(--context));
            max-width: calc(400vw / var(--context));

            @include breakpoint-down(small) {
                width: 100%;
            }

            h2 {
                margin-bottom: 0;
                font-weight: 600;
                line-height: 1.33;
                color: $color-white;
            }

            p {
                color: $color-grey;
                margin-top: calc(14vvw / var(--context));
                margin-bottom: 0;
                font-weight: 400;
                font-size: calc(16vw / var(--context));
                line-height: 1.75;
                opacity: .8;
            }

            .social-icons {
                margin-top: calc(28vw / var(--context));
                display: flex;
                gap: calc(26vw / var(--context));

                svg {
                    width: calc(16vw / var(--context));
                    height: calc(16vw / var(--context));
                    
                    @include breakpoint-down(small) {
                        width: calc(40vw / var(--context));
                        height: calc(40vw / var(--context));
                        padding: calc(10vw / var(--context));
                    }

                    &:hover {
                        background-color: $color-jasmine;
                        border-radius: calc(40vw / var(--context));
                        fill:$color-space;
                    }
                }
            }
        }
    }
}

.copyright {
    background-color: $color-white;

    .copyright-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: calc(32vw / var(--context)) 0;

        @include breakpoint-down(small) {
            flex-direction: column;
            gap: calc(20vw / var(--context));
        }

        p {
            font-weight: 500;
            color: $color-peacoat;
            font-size: calc(16vw / var(--context));
            line-height: 1.75;
            margin-bottom: 0;
            @include breakpoint-down(small) {
                text-align: center;
            }
              
        }

        ul {
            display: flex;
            justify-content: flex-end;
            gap: calc(32vw / var(--context));

            li {
                color: $color-peacoat;
                font-size: calc(18vw / var(--context));
                line-height: 1.25;
                font-weight: 500;

                @include breakpoint-down(small) {
                    font-size: calc(9vw / var(--context));
                }
            }
        }

    }
}