.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

/* text alignment */
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}

/* border radius */
.br-all {
    border-radius: calc(20vw / var(--context));
}

/* displays */
.d-none-sm {
    @include breakpoint-down(small) {
        display: none;
    }
}
.d-none-md {
    @include breakpoint-down(xmedium) {
        display: none;
    }
}
.d-none-lg {
    @include breakpoint-down(large) {
        display: none;
    }
}

.d-block-sm {
    display: none;
    @include breakpoint-down(small) {
        display: block;
    }
}
.d-flex-sm {
    display: none;
    @include breakpoint-down(small) {
        display: flex;
    }
}
.d-block-md {
    display: none;
    @include breakpoint-down(xmedium) {
        display: block;
    }
}
.d-flex-md {
    display: none;
    @include breakpoint-down(xmedium) {
        display: flex;
    }
}
.d-block-lg {
    display: none;
    @include breakpoint-down(large) {
        display: block;
    }
}
.d-flex-lg {
    display: none;
    @include breakpoint-down(large) {
        display: flex;
    }
}

/* global classes font related */
.caps {
    text-transform: uppercase;
}
.ff-def {
    font-family: $font-def;
}

/* text colors */
.text-white {
    color: $color-white;
    * {
        color: $color-white;
    }
}
.text-black {
    color: $color-black;
}
.text-white {
    color: $color-white;
}

/* background colors */
.bg-black {
    background-color: $color-black;
}
.bg-white {
    background-color: $color-white;
}

/* font weights */
strong,
b {
    font-weight: $bold;
}
.fw-t {
    font-weight: $thin;
}
.fw-xl {
    font-weight: $x-light;
}
.fw-l {
    font-weight: $light;
}
.fw-n {
    font-weight: $normal;
}
.fw-m {
    font-weight: $medium;
}
.fw-sb {
    font-weight: $s-bold;
}
.fw-b {
    font-weight: $bold;
}
.fw-xb {
    font-weight: $x-bold;
}
.fw-bl {
    font-weight: $black;
}

/* image alignment */
img {
    &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: calc(32vw / var(--context));
    }
    &.alignleft {
        display: block;
        margin-right: auto;
        margin-top: calc(32vw / var(--context));
    }
    &.alignright {
        display: block;
        margin-left: auto;
        margin-top: calc(32vw / var(--context));
    }
}

/* variable width */
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}
.col-25 {
    width: 25%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-30 {
    width: 30%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-35 {
    width: 35%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-40 {
    width: 40%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-45 {
    width: 45%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-50 {
    width: 50%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-55 {
    width: 55%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-60 {
    width: 60%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-65 {
    width: 65%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-70 {
    width: 70%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-75 {
    width: 75%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-80 {
    width: 80%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-85 {
    width: 85%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-90 {
    width: 90%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-95 {
    width: 95%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}
.col-100 {
    width: 100%;
    @include breakpoint-down(small) {
        width: 100%;
    }
}

/* z index */
.zi-1 {
    position: relative;
    z-index: 1;
}
.zi-2 {
    position: relative;
    z-index: 2;
}
.zi-3 {
    position: relative;
    z-index: 3;
}
.zi-4 {
    position: relative;
    z-index: 4;
}