@mixin button($background, $color) {
    background-color: $background;
    color: $color;
    text-transform: uppercase;
    text-align: center;
    font-weight: $medium;
    font-family: $font-def;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(.86, .01, .15, .99);

    font-size: calc(16vw / var(--context));
    line-height: 1.75;
    padding: calc(16vw / var(--context)) calc(56vw / var(--context));
    border-radius: calc(41vw / var(--context));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: calc(2vw / var(--context)) solid rgba(244, 246, 252, 0.2);

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0;
    }

    @include breakpoint-down(small) {
        font-size: calc(15vw / var(--context));
        padding: calc(15vw / var(--context)) calc(50vw / var(--context));

    }
}

.global-btn {
    @include button($color-space, $color-white);

    @include breakpoint-down(small) {
        display: flex;
        justify-content: center;
        width: fit-content !important;
        margin: 0 auto;
    }

    transition: 0.8s;
    transform: perspective(1px) translateZ(0);

    &:hover {
        color: $color-black !important;
        box-shadow: 0 0 0 10px rgba(250, 250, 250, 0.1)
    }

    &::before {
        clip-path: circle(0.5% at 50% 50%);
        background: $color-jasmine;
        transition: 0.8s;
        opacity: 1;
    }

    &:hover::before {
        clip-path: circle(100% at 50% 50%);
    }

}